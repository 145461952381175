

















































import {
  Component, PropSync, Watch, Vue,
} from 'vue-property-decorator';
import { ChangeBankAccountStatusDialogViewModel }
  from '@/vue-app/view-models/allianz-dashboard/allianz-actions/change-bank-account/change-bank-account-status-dialog-view-model';

@Component({
  name: 'ChangeBankAccountStatusDialog',
  components: {
    StatusAccountOnValidation: () => import('./status/StatusAccountOnValidation.vue'),
    SignatureRequested: () => import('./status/SignatureRequested.vue'),
    RequestSend: () => import('./status/RequestSend.vue'),
  },
})
export default class ChangeBankAccountStatusDialog extends Vue {
  @PropSync('email', { type: String })
  synced_email!: string;

  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  @PropSync('changeStatus', { type: String })
  synced_change_status!: string;

  @Watch('synced_change_status')
  onStatusChange(synced_change_status: string) {
    this.change_bank_account_status_model.setStatus(synced_change_status);
  }

  change_bank_account_status_model = Vue.observable(
    new ChangeBankAccountStatusDialogViewModel(),
  );

  accept() {
    this.synced_is_open = false;
  }
}

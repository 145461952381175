import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { Values } from '@/modules/shared/domain/i18n/types';
import Translator from '@/modules/shared/domain/i18n/translator';

export class ChangeBankAccountStatusDialogViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.change-bank-account-status';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  readonly STEPS = [
    {
      step: 0,
      component: 'StatusAccountOnValidation',
    },
    {
      step: 1,
      component: 'SignatureRequested',
    },
    {
      step: 2,
      component: 'RequestSend',
    },
  ];

  current_step = 0;

  final_step = this.STEPS.length - 1;

  current_component = this.STEPS[this.current_step];

  menu_steps = [1, 2, 3]

  setStatus(status: string) {
    switch (status) {
      case 'ACCOUNT_STATEMENT_ON_VALIDATION':
        this.current_step = 0;
        break;
      case 'SIGNATURE_REQUESTED':
        this.current_step = 1;
        break;
      case 'REQUEST_SEND':
        this.current_step = 2;
        break;
      default:
        this.current_step = 0;
        break;
    }
    this.current_component = this.STEPS[this.current_step];
  }
}
